import { Inject, inject, Injectable } from '@angular/core';
import { BaseApi } from '@ministrary/shared/apis/base.api';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

import { iCustomGroupNames } from '../interfaces/custom-group-names.interface';
import { iEnvironment } from '../interfaces/environment.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomGroupNamesApi extends BaseApi<iCustomGroupNames> {
  private churchService = inject(ChurchService);

  constructor(@Inject('environment') environment: iEnvironment) {
    super('custom_group_names', environment.apiUrl);
  }

  async loadByChurch() {
    const { data } = await this.select().match({ church_id: this.churchService.churchId }).returns<iCustomGroupNames>().single();
    return data as unknown as iCustomGroupNames;
  }

  async updateByChurch(data: Partial<iCustomGroupNames>) {
    return this.update(data).match({ id: data.id, church_id: this.churchService.churchId });
  }
}
