import { inject, Injectable, signal } from '@angular/core';
import { CustomGroupNamesApi } from '@ministrary/shared/apis/custom-group-names.api';
import { ePronoun } from '@ministrary/shared/enums/pronoun.enum';
import { iCustomGroupNames } from '@ministrary/shared/interfaces/custom-group-names.interface';
import { ChurchService } from '@ministrary/shared/services/church/church.service';

@Injectable({
  providedIn: 'root'
})
export class CustomGroupNamesService {
  private churchService = inject(ChurchService);
  private customGroupNamesApi = inject(CustomGroupNamesApi);

  customGroupNames: iCustomGroupNames;

  groupPronoun = signal<ePronoun>(ePronoun.O);
  groupSingular = signal<string>('Grupo');
  groupPlural = signal<string>('Grupos');

  networkSupervisorPronoun = signal<ePronoun>(ePronoun.O);
  networkSupervisorSingular = signal<string>('Supervisor');
  networkSupervisorPlural = signal<string>('Supervisores');

  networkPronoun = signal<ePronoun>(ePronoun.O);
  networkSingular = signal<string>('Rede');
  networkPlural = signal<string>('Redes');

  coordinatorPronoun = signal<ePronoun>(ePronoun.O);
  coordinatorSingular = signal<string>('Coordenador');
  coordinatorPlural = signal<string>('Coordenadores');

  leaderPronoun = signal<ePronoun>(ePronoun.O);
  leaderSingular = signal<string>('Líder');
  leaderPlural = signal<string>('Líderes');

  async load() {
    this.customGroupNames = await this.customGroupNamesApi.loadByChurch();
    if (!this.customGroupNames) await this.create();

    if (this.customGroupNames.group_pronoun) this.groupPronoun.set(this.customGroupNames.group_pronoun as ePronoun);
    if (this.customGroupNames.group_singular) this.groupSingular.set(this.customGroupNames.group_singular);
    if (this.customGroupNames.group_plural) this.groupPlural.set(this.customGroupNames.group_plural);

    if (this.customGroupNames.network_supervisor_pronoun) this.networkSupervisorPronoun.set(this.customGroupNames.network_supervisor_pronoun as ePronoun);
    if (this.customGroupNames.network_supervisor_singular) this.networkSupervisorSingular.set(this.customGroupNames.network_supervisor_singular);
    if (this.customGroupNames.network_supervisor_plural) this.networkSupervisorPlural.set(this.customGroupNames.network_supervisor_plural);

    if (this.customGroupNames.network_pronoun) this.networkPronoun.set(this.customGroupNames.network_pronoun as ePronoun);
    if (this.customGroupNames.network_singular) this.networkSingular.set(this.customGroupNames.network_singular);
    if (this.customGroupNames.network_plural) this.networkPlural.set(this.customGroupNames.network_plural);

    if (this.customGroupNames.coordinator_pronoun) this.coordinatorPronoun.set(this.customGroupNames.coordinator_pronoun as ePronoun);
    if (this.customGroupNames.coordinator_singular) this.coordinatorSingular.set(this.customGroupNames.coordinator_singular);
    if (this.customGroupNames.coordinator_plural) this.coordinatorPlural.set(this.customGroupNames.coordinator_plural);

    if (this.customGroupNames.leader_pronoun) this.leaderPronoun.set(this.customGroupNames.leader_pronoun as ePronoun);
    if (this.customGroupNames.leader_singular) this.leaderSingular.set(this.customGroupNames.leader_singular);
    if (this.customGroupNames.leader_plural) this.leaderPlural.set(this.customGroupNames.leader_plural);
  }

  async create() {
    const { data } = await this.customGroupNamesApi
      .insert({
        church_id: this.churchService.churchId!,

        group_pronoun: ePronoun.O,
        group_singular: 'Grupo',
        group_plural: 'Grupos',

        network_supervisor_pronoun: ePronoun.O,
        network_supervisor_singular: 'Supervisor',
        network_supervisor_plural: 'Supervisores',

        network_pronoun: ePronoun.O,
        network_singular: 'Rede',
        network_plural: 'Redes',

        coordinator_pronoun: ePronoun.O,
        coordinator_singular: 'Coordenador',
        coordinator_plural: 'Coordenadores',

        leader_pronoun: ePronoun.O,
        leader_singular: 'Líder',
        leader_plural: 'Líderes'
      })
      .select('*')
      .single();

    this.customGroupNames = data;
  }

  async update() {
    await this.customGroupNamesApi.updateByChurch({
      id: this.customGroupNames.id,

      group_pronoun: this.groupPronoun(),
      group_singular: this.groupSingular(),
      group_plural: this.groupPlural(),

      network_supervisor_pronoun: this.networkSupervisorPronoun(),
      network_supervisor_singular: this.networkSupervisorSingular(),
      network_supervisor_plural: this.networkSupervisorPlural(),

      network_pronoun: this.networkPronoun(),
      network_singular: this.networkSingular(),
      network_plural: this.networkPlural(),

      coordinator_pronoun: this.coordinatorPronoun(),
      coordinator_singular: this.coordinatorSingular(),
      coordinator_plural: this.coordinatorPlural(),

      leader_pronoun: this.leaderPronoun(),
      leader_singular: this.leaderSingular(),
      leader_plural: this.leaderPlural()
    });
  }
}
